import React, { useState, useRef, RefObject } from "react";
import PropTypes from "prop-types";

interface IInputProps {
	type: string;
	value: string;
	onChange: (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => void;
	label: string;
	placeholder?: string;
	onKeyPress?: (
		event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
	) => void;
	onKeyDown?: (
		event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
	) => void;
	onFocus?: () => void;
	resize?: boolean;
	onBlur?: (event: any) => void;
	children?: JSX.Element[] | JSX.Element | null;
	myRef?: RefObject<HTMLInputElement>;
	myRefTextArea?: RefObject<HTMLTextAreaElement>;
	name?: string;
	noBorder?: boolean;
	style?: React.CSSProperties | undefined;
	cols?: number;
	rows?: number;
	textInputClassName?: string;
}

const Input: React.FC<IInputProps> = ({
	type,
	value,
	onChange,
	placeholder,
	label,
	onKeyPress,
	onFocus,
	resize,
	onBlur,
	children,
	myRef,
	noBorder,
	style,
	onKeyDown,
	cols,
	rows,
	myRefTextArea,
	textInputClassName,
}) => {
	const [focus, setFocus] = useState(false);
	const input = useRef<HTMLInputElement>(null);
	const textArea = useRef<HTMLTextAreaElement>(null);

	const handleOnFocus = () => {
		if (onFocus) {
			onFocus();
		}
		setFocus(true);
	};

	const handleOnBlur = () => {
		if (onBlur && onFocus) {
			onFocus();
		}
		if (value.trim().length === 0) {
			setFocus(false);
		}
	};

	if (type === "textarea") {
		return (
			<div className="textInputComponent">
				<div className="form-item">
					<p
						onClick={() => {
							if (myRef && myRef?.current !== null) {
								myRef?.current.focus();
							}

							if (textArea && textArea?.current !== null) {
								textArea?.current.focus();
							}
						}}
						className={`formLabel ${
							focus || value.trim().length > 0 ? "formTop" : ""
						}`}
					>
						{label}
					</p>
					<textarea
						style={{ resize: "none" }}
						value={value}
						onFocus={handleOnFocus}
						onBlur={handleOnBlur}
						ref={myRefTextArea ? myRefTextArea : textArea}
						cols={cols || 4}
						rows={rows || 4}
						wrap="hard"
						onChange={onChange}
						onKeyPress={onKeyPress}
						placeholder={placeholder}
						className={`${
							!resize ? "no-resize" : ""
						} "bg-neutral-50 border text-neutral-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-neutral-800 border-neutral-500  text-white"`}
						autoComplete="off"
					/>
				</div>
			</div>
		);
	}

	const classNames = [
		"formLabel",
		"bebas",
		focus || value.trim().length > 0 ? "formTop" : "",
	].join(" ");

	return (
		<div
			className={`textInputComponent ${noBorder ? "noBorder" : ""} ${
				focus || value.trim().length > 0 ? "focus" : ""
			} ${textInputClassName || ""}`}
			style={style}
		>
			<div className="form-item">
				<p
					onClick={() => {
						if (myRef && myRef?.current !== null) {
							myRef?.current.focus();
						}

						if (input && input?.current !== null) {
							input?.current.focus();
						}
					}}
					className={classNames}
				>
					{label}
				</p>
				<input
					type={type}
					value={value}
					onFocus={handleOnFocus}
					onBlur={handleOnBlur}
					ref={myRef ? myRef : input}
					onChange={onChange}
					onKeyPress={onKeyPress}
					onKeyDown={onKeyDown}
					placeholder={placeholder}
					className="form-style bebas"
					autoComplete="off"
				/>
				<div className={`inputChild`}>{children}</div>
			</div>
		</div>
	);
	// }
};

Input.propTypes = {
	type: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
};

Input.defaultProps = {
	resize: true,
};

export default Input;
