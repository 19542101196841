import React, { FC } from "react";
import "./Button.scss";

interface IButton {
	onClick: React.MouseEventHandler<HTMLButtonElement>;
	className?: string;
	text?: string;
	children?: any;
	disabled?: boolean;
	loading?: boolean;
	loadingText?: string;
	inverted?: boolean;
	fontSize?: string;
}

const Button = ({
	onClick,
	className,
	text,
	children,
	disabled,
	loading,
	loadingText,
	inverted,
	fontSize,
}: IButton) => {
	const buttonClassName = [
		"globalButton py-2.5 px-5 border border-solid  rounded-xl  ",
		"bebas",
		...(fontSize ? [fontSize] : ["text-base"]),
		...(inverted
			? [
					"bg-neutral-50 border-neutral-900 text-neutral-900 hover:text-neutral-50 hover:bg-neutral-900",
			  ]
			: [
					"bg-neutral-900 border-neutral-50 text-neutral-50 hover:text-neutral-900 hover:bg-neutral-50",
			  ]),
		// ...(loading ? [] : ["hover:text-neutral-900 hover:bg-neutral-50"]),
		...([className] || []),
	].join(" ");

	return (
		<button className={buttonClassName} onClick={onClick} disabled={!!disabled}>
			{loading ? loadingText || "Loading" : text || children}
		</button>
	);
};

export default Button;
