import { viewModeType } from "../../Types";

export const initialState: {
	darkMap: boolean;
	darkMode: boolean;
	showLoginModal: boolean;
	viewMode: viewModeType;
	showMap: boolean;
	showMapMobile: boolean;
} = {
	darkMap: true,
	darkMode: false,
	showLoginModal: false,
	viewMode: "laptop",
	showMap: !!parseInt(localStorage.getItem("showMap") || "1"),
	showMapMobile: false,
};

const eventsReducer = (state = initialState, action: any) => {
	switch (action.type) {
		case "SET_MAP_IS_DARK":
			return {
				...state,
				darkMap: action.payload,
			};
		case "SET_DARK_MODE":
			return {
				...state,
				darkMode: action.payload,
			};
		case "SHOW_GLOBAL_LOGIN_MODAL":
			return {
				...state,
				showLoginModal: action.payload,
			};
		case "SET_VIEW_MODE":
			return {
				...state,
				viewMode: action.payload,
			};
		case "SET_SHOW_MAP":
			return {
				...state,
				showMap: action.payload,
			};
		case "SET_SHOW_MAP_MOBILE":
			return {
				...state,
				showMapMobile: action.payload,
			};
		default:
			return state;
	}
};

export default eventsReducer;
