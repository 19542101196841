import axios from "axios";
import React, { RefObject, useEffect, useRef, useState } from "react";
import Input from "../../shared/global/Inputs/Input";
import usePlacesAutocomplete, {
	getGeocode,
	getLatLng,
} from "use-places-autocomplete";
import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";
import { CircularProgress } from "@mui/material";
import { isEmptyObject } from "../../../helpers";

interface INavbarMapboxGeocoderProps {
	geoCodeSearch: any;
	setGeoCodeSearch: any;
	clearSuggestions: any;
	geoCodeStatus: any;
	geoCodeData: any;
	///
	usedFindLocation: boolean;
	setUsedFindLocation: (usedFindLocation: boolean) => void;
	// setDateInputFocus: (dateInputFocus: boolean) => void;
	locationInput: any;
	findLocationClicked: boolean;
	handleFindLocation: () => void;
	onSelected: any;
	selected: any;
	handleSubmitButton: any;
	geoCoderInputRef: RefObject<HTMLInputElement>;
	setSearchIsActive: any;
}

const NavbarMapboxGeocoder = ({
	geoCodeSearch,
	setGeoCodeSearch,
	clearSuggestions,
	geoCodeStatus,
	geoCodeData,
	///
	usedFindLocation,
	setUsedFindLocation,
	// setDateInputFocus,
	locationInput,
	findLocationClicked,
	handleFindLocation,
	onSelected,
	selected,
	handleSubmitButton,
	geoCoderInputRef,
	setSearchIsActive,
}: INavbarMapboxGeocoderProps) => {
	const [arrowSelection, setArrowSelection] = useState<number>(-1);

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (geoCodeData.length === 0) return;
		//@ts-ignore
		const code = e.keyCode;

		const UP = 38;
		const DOWN = 40;
		const ENTER = 13;

		if (code === UP) {
			//Direction
			if (arrowSelection === -1) return;

			setArrowSelection((prevState) => {
				return prevState - 1;
			});
		} else if (code === DOWN) {
			//Direction
			if (arrowSelection === geoCodeData.length - 1) return;

			setArrowSelection((prevState) => {
				return prevState + 1;
			});
		} else if (code === ENTER) {
			///TODO: BUG IN THIS SCOPE

			console.log({
				sfsfsf: geoCodeData[arrowSelection]?.description,
				selected: selected?.formatted_address,
			});

			if (!isEmptyObject(selected)) {
				console.log("first console");
				return handleSubmitButton();
			}

			// if (
			// 	isEmptyObject(selected) &&
			// 	geoCodeData[arrowSelection]?.description === selected?.formatted_address
			// ) {
			// 	console.log("second console");
			// 	setSearchIsActive(false);
			// }

			if (arrowSelection < 0) return;
			if (arrowSelection > geoCodeData.length - 1) return;

			console.log("geoCodeData[arrowSelection]: ", geoCodeData[arrowSelection]);

			handleSelect(geoCodeData[arrowSelection])();
		}
	};

	useEffect(() => {
		setArrowSelection(-1);
	}, [geoCodeData.length]);

	const handleSelect =
		({ description }: any) =>
		() => {
			// When user selects a place, we can replace the keyword without request data from API
			// by setting the second parameter to "false"
			setGeoCodeSearch(description, false);
			clearSuggestions();
			setArrowSelection(-1);

			// Get latitude and longitude via utility functions
			getGeocode({ address: description }).then((results) => {
				const { lat: latitude, lng: longitude } = getLatLng(results[0]);
				const { formatted_address } = results[0];
				console.log({ description, formatted_address });
				onSelected({ longitude, latitude }, { formatted_address: description });
			});
		};

	return (
		<div className="navbarMapboxGeocoder relative">
			<Input
				type="location"
				label="Where"
				placeholder="Location"
				value={usedFindLocation ? "Your Location" : geoCodeSearch}
				noBorder
				myRef={geoCoderInputRef}
				textInputClassName="pt-0 w-full mt-1  rounded-2xl md:p-2.5"
				// onKeyDown={handleKeyDown}
				onChange={(e) => {
					setUsedFindLocation(false);
					setGeoCodeSearch(e.target.value);
				}}
			>
				<>
					{findLocationClicked && <CircularProgress />}
					{!geoCodeSearch && !findLocationClicked ? (
						<NearMeOutlinedIcon onClick={handleFindLocation} />
					) : null}
				</>
			</Input>

			{geoCodeStatus === "OK" && (
				<div className="absolute top-full left-0 bg-neutral-50 w-full p-2 rounded-xl mt-2 shadow-result">
					{geoCodeData.map((suggestion: any, index: number) => {
						const {
							place_id,
							structured_formatting: { main_text, secondary_text },
						} = suggestion;

						return (
							<div
								className={`border-solid border bebas text-lg px-2 py-1 rounded-lg ${
									arrowSelection === index
										? "border-neutral-900"
										: "border-transparent"
								} hover:border-neutral-900`}
								key={place_id}
								onClick={handleSelect(suggestion)}
							>
								{main_text} -{" "}
								<span className="text-neutral-600 bebas">{secondary_text}</span>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default NavbarMapboxGeocoder;
