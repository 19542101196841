import { viewModeType } from "../../Types";
import { AppDispatch } from "../../ConsumerAppEntry";

export const setDarkMode = (darkMode: boolean) => (disaptch: AppDispatch) => {
	disaptch({
		type: "SET_DARK_MODE",
		payload: darkMode,
	});
};

export const setViewMode =
	(viewMode: viewModeType) => (disaptch: AppDispatch) => {
		disaptch({
			type: "SET_VIEW_MODE",
			payload: viewMode,
		});
	};

export const setShowMap = (showMap: boolean) => (disaptch: AppDispatch) => {
	disaptch({
		type: "SET_SHOW_MAP",
		payload: showMap,
	});
};

export const setShowMapMobile =
	(showMapMobile: boolean) => (disaptch: AppDispatch) => {
		disaptch({
			type: "SET_SHOW_MAP_MOBILE",
			payload: showMapMobile,
		});
	};

export const showLoginModal =
	(showModal: boolean) => (dispatch: AppDispatch) => {
		dispatch({ type: "SHOW_GLOBAL_LOGIN_MODAL", payload: !!showModal });
	};

export const setDarkMap = (isDark: boolean) => (dispatch: AppDispatch) => {
	dispatch({ type: "SET_MAP_IS_DARK", payload: isDark });
};
