import React, { Dispatch, FC, SetStateAction, useState } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "../../components/global/Button";
import { signInEmail, signUpEmail } from "../../redux/actions/userActions";
import { isEmail } from "../../helpers";
import { useDispatch } from "react-redux";
import LoginModal from "./EmailLogin/LoginModal";
import SignUp from "./EmailLogin/SignUp";
import Login from "./EmailLogin/Login";
import { showLoginModal } from "../../redux/actions/viewActions";

interface IEmailLoginProps {
	logIn: boolean;
	setLogIn: Dispatch<SetStateAction<boolean>>;
	spotifyMiddleStage: boolean;
	setSpotifyMiddleStage: Dispatch<SetStateAction<boolean>>;
}

const EmailLogin: FC<IEmailLoginProps> = ({
	logIn,
	setLogIn,
	spotifyMiddleStage,
}) => {
	const dispatch = useDispatch();

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [fullName, setFullName] = useState("");
	const [passwordSignUp, setPasswordSignUp] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [errorMessage, setErrorMessage] = useState<string | undefined>(
		undefined
	);
	const [age, setAge] = useState<number | undefined>(undefined);

	let currentErrorMessage: NodeJS.Timeout | null = null;

	const handleSubmit = async () => {
		if (!isEmail(email)) return setErrorMessage("Invalid email");

		if (logIn) {
			if (!email.trim().length || !password.trim().length) {
				return setErrorMessage("Must provide email and password");
			}
			const response = await dispatch(signInEmail({ email, password }));
			//@ts-ignore
			const responseErrorMessage = response?.errorMessage;
			console.log({ responseresponse: response });
			if (responseErrorMessage) {
				return setErrorMessage(responseErrorMessage);
			}

			//@ts-ignore
			if (response.payload.auth) {
				dispatch(showLoginModal(false));
			}
		} else {
			if (
				!email.trim().length ||
				!passwordSignUp.trim() ||
				!fullName.trim().length ||
				!age ||
				age < 18
			) {
				return setErrorMessage(
					"Must provide a full name, a valid age, a valid email, and password"
				);
			}
			if (passwordSignUp !== confirmPassword) {
				return setErrorMessage("Passwords do not match");
			}

			const response = await dispatch(
				signUpEmail({
					fullName,
					email,
					password: passwordSignUp,
					confirmPassword,
					age,
				})
			);
			//@ts-ignore
			const responseErrorMessage = response?.errorMessage;
			if (responseErrorMessage) {
				return setErrorMessage(responseErrorMessage);
			}

			//@ts-ignore
			if (response.payload.auth) {
				dispatch(showLoginModal(false));
			}
		}
	};

	const handleErrorRemoval = () => {
		// if (currentErrorMessage) {
		// 	clearTimeout(currentErrorMessage);
		// }
		currentErrorMessage = null;
		setErrorMessage(undefined);
	};

	const handleEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (e.key === "Enter") {
			console.log("submitted");
			handleSubmit();
		}
	};

	const handleSetLogin = (showLogin: boolean) => {
		handleErrorRemoval();
		setLogIn(showLogin);
	};

	return (
		<div className="loginModal px-5 pt-2.5 pb-5 rounded-lg email">
			{spotifyMiddleStage ? (
				<div>
					<CircularProgress />
				</div>
			) : (
				<>
					<div>
						{/* <img src="/static/wvey-white.svg" width="120px" alt="wvey logo" /> */}
						<h3 className="mb-4 text-4xl bebas font-medium text-white">
							Sign into your Wvey account
						</h3>
						{errorMessage && (
							<div
								onClick={handleErrorRemoval}
								className="flex p-4 mb-4 text-sm rounded-lg bg-yellow-200 text-yellow-800"
								role="alert"
							>
								<svg
									aria-hidden="true"
									className="flex-shrink-0 inline w-5 h-5 mr-3"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
										clipRule="evenodd"
									></path>
								</svg>
								<span className="sr-only">Info</span>
								<div>{errorMessage}</div>
							</div>
						)}
						<div className="space-y-6 text-left">
							{logIn ? (
								<Login
									setLogIn={handleSetLogin}
									email={email}
									setEmail={setEmail}
									password={password}
									setPassword={setPassword}
									onKeyDown={handleEnter}
									handleSubmit={handleSubmit}
								/>
							) : (
								<SignUp
									setLogIn={handleSetLogin}
									fullName={fullName}
									setFullName={setFullName}
									email={email}
									setEmail={setEmail}
									passwordSignUp={passwordSignUp}
									setPasswordSignUp={setPasswordSignUp}
									confirmPassword={confirmPassword}
									setConfirmPassword={setConfirmPassword}
									onKeyDown={handleEnter}
									handleSubmit={handleSubmit}
									age={age}
									setAge={setAge}
								/>
							)}
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default EmailLogin;
