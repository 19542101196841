import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import { rootReducer } from "./reducers/rootReducer";

interface IInitialState {}

export default function configureStore(initialState?: IInitialState) {
	const middlewareEnhancer = applyMiddleware(thunkMiddleware);
	const composedEnhancers = composeWithDevTools(middlewareEnhancer);

	//@ts-ignore
	const store = createStore(rootReducer, undefined, composedEnhancers);

	return store;
}
