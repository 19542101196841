import { useState } from "react";
import FormInput from "../../shared/global/Inputs/FormInput";
import Button from "../Button";
import GlobalModal from "../GlobalModal";
import { useDispatch } from "react-redux";
import { updateUserAge } from "../../../redux/actions/userActions";

const AgeRestrictionModal = () => {
	const [age, setAge] = useState<number | undefined>(undefined);
	const [sending, setSending] = useState(false);

	const dispatch = useDispatch();

	const handleAgeSubmission = async () => {
		if (!age) return;
		setSending(true);

		if (age < 16 || age > 99) {
			//show warning then sign user out
			setSending(false);
		} else {
			dispatch(updateUserAge(age));
		}
	};

	return (
		<GlobalModal
			onBackgroundClick={() => {}}
			className="max-w-maincard md:max-w-xl w-full "
		>
			<div className="p-7 grid gap-6 w-full ">
				<div>
					<h1 className="bebas text-4xl text-neutral-50 mb-4">
						Minimum Age Confirmation:
					</h1>
					<FormInput
						type="number"
						label=""
						noLabel
						value={age?.toString() || ""}
						placeholder="Enter your age"
						onChange={(e) => setAge(parseInt(e.target.value))}
					/>
				</div>
				<div className="flex ml-auto mr-0 mt-5 max-w-full md:mt-0 md:max-w-[150px] w-full ">
					{/* <CloseButton onClick={() => alert("LOG USER OUT")} /> */}
					<Button
						onClick={() => handleAgeSubmission()}
						className="ml-3 w-full"
						text={sending ? "Sending" : "Save"}
						loadingText={"Sending"}
						loading={sending}
					/>
				</div>
			</div>
		</GlobalModal>
	);
};
export default AgeRestrictionModal;
