import { IUser } from "../../globalTypes";
export const initialState: {
	loggedInUser: { auth: boolean; user: IUser | null };
	sessionLoaded: boolean;
} = {
	loggedInUser: { auth: false, user: null },
	sessionLoaded: false,
};

const userReducer = (
	state = initialState,
	{
		type,
		payload,
	}: {
		type: string;
		payload: any;
	}
) => {
	switch (type) {
		case "SESSION_L0ADED":
			return {
				...state,
				sessionLoaded: true,
			};
		case "GET_SESSION":
		case "LOG_IN":
		case "SIGN_UP":
			return {
				...state,
				loggedInUser: payload,
			};
		case "CLEAR_STATE_AFTER_LOG_OUT":
			return {
				...state,
				loggedInUser: { auth: false, user: null },
				sessionLoaded: false,
			};
		case "UPDATE_USER":
			return {
				...state,
				loggedInUser: {
					...state.loggedInUser,
					user: payload,
				},
			};
		default:
			return state;
	}
};

export default userReducer;
