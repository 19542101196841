import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import Main from "./ConsumerAppEntry";
import "./index.css";

//Sentry logging
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
	dsn: "https://4083d81e2676419ba82485440e897286@o4504431670984704.ingest.sentry.io/4504431675834372",
	integrations: [new BrowserTracing()],
	// beforeSend(event, hint) {
	// 	// Check if it is an exception, and if so, show the report dialog
	// 	if (event.exception) {
	// 		Sentry.showReportDialog({
	// 			eventId: event.event_id,
	// 			user: {
	// 				email: "testing@gmail.com",
	// 			},
	// 		});
	// 	}
	// 	return event;
	// },
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});

// replace console.* for disable log on production
if (process.env.NODE_ENV === "production") {
	console.log = () => {};
	console.error = () => {};
	console.debug = () => {};
}

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
	// <StrictMode>
	<Main />
	// </StrictMode>
);
