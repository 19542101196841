import { lazy } from "react";

// lazy components
export const Home = lazy(() => import("./pages/Home/Home"));
export const SingleEvent = lazy(() => import("./pages/Event"));
export const ResultPage = lazy(() => import("./pages/Events/ResultPage"));
export const PrivacyPolicy = lazy(
	() => import("./pages/Information/PrivacyPolicy")
);
export const TermsAndConditions = lazy(
	() => import("./pages/Information/TermsAndConditions")
);
export const ManageEventWrapper = lazy(
	() => import("./Dashboard/Components/ManageEventWrapper")
);
export const Profile = lazy(() => import("./pages/Profile/Profile"));
export const Account = lazy(() => import("./pages/Account"));
export const PageNotFound = lazy(
	() => import("./components/shared/global/PageNotFound")
);
export const HostApp = lazy(() => import("./HostApp"));
