import React, { RefObject, useEffect, useRef, useState } from "react";
import Input from "../../shared/global/Inputs/Input";

import SearchIcon from "@mui/icons-material/Search";
import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";
import NavbarDatePicker from "../../shared/global/Inputs/NavbarDatePicker";
import { History } from "history";
import { isAString } from "../../../helpers";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import CircularProgress from "@material-ui/core/CircularProgress";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import mapboxgl from "mapbox-gl";
import usePlacesAutocomplete from "use-places-autocomplete";
import "./HomePageModal.scss";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import NavbarMapboxGeocoder from "../../global/Navbar/NavbarMapboxGeocoder";

interface IHomePageModalProps {
	selected: any;
	setSelected: any;
	geoCoderInputRef: RefObject<HTMLInputElement>;
	setSearchIsActive: any;
}

interface IViewPort {
	latitude: number;
	longitude: number;
	transitionDuration: number;
	zoom: number;
}

type placeType = "country" | "region" | "district" | "place" | "locality";

interface IItem {
	bbox: [number, number, number, number];
	center: [number, number];
	context: {
		id: string;
		short_code?: string;
		text: string;
		wikidata: string;
	}[];
	geometry: { type: "Point"; coordinates: [number, number] };
	id: string;
	place_name: string;
	place_type: placeType[];
	properties: { [key: string]: any };
	relevance: number;
	text: string;
	type: string;
}

const HomePageModal: React.FC<IHomePageModalProps> = ({
	selected,
	setSelected,
	geoCoderInputRef,
	setSearchIsActive,
}) => {
	const navigate = useNavigate();
	const { search } = useLocation();
	const [searchParams] = useSearchParams();
	const [name, setName] = useState();

	const [viewport, setViewport] = useState<{
		longitude: number;
		latitude: number;
	}>({ longitude: 0, latitude: 0 });

	const [currentSearchZoom, setCurrentSearchZoom] = useState<number | null>(
		null
	);

	const [date, setDate] = useState<Date | undefined>(undefined);
	const [usedFindLocation, setUsedFindLocation] = useState<boolean>(false);
	const [findLocationClicked, setFindLocationClicked] =
		useState<boolean>(false);

	const {
		ready,
		value: geoCodeSearch,
		suggestions: { status: geoCodeStatus, data: geoCodeData },
		setValue: setGeoCodeSearch,
		clearSuggestions,
	} = usePlacesAutocomplete({
		requestOptions: {
			/* Define search scope here */
			componentRestrictions: {
				country: "GB",
			},
		},
		debounce: 300,
	});

	const handleOnChange = (calendarDate: Date) => {
		setDate(calendarDate);
	};

	let redirect = isAString(searchParams.get("redirect"))
		? //@ts-ignore
		  parseInt(searchParams.get("redirect"))
		: undefined;

	const locationInput = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (redirect && locationInput?.current) {
			locationInput.current.focus();
		}
	}, [redirect]);

	useEffect(() => {
		console.log(selected);
	}, [selected]);

	const onSelected = (
		viewport: Pick<IViewPort, "longitude" | "latitude">,
		item: IItem
	) => {
		setViewport(viewport);
		setSelected(item);
		setCurrentSearchZoom(12.5);
	};

	const locationSuccess = (pos: any) => {
		const coordinates = pos.coords;
		console.log("Your current position is: ", coordinates);
		setViewport({
			longitude: coordinates.longitude,
			latitude: coordinates.latitude,
		});
		setCurrentSearchZoom(13);
		setUsedFindLocation(true);
		setFindLocationClicked(false);
	};

	const locationError = (err: GeolocationPositionError) => {
		console.log("error: ", err.code, err.message);
		setFindLocationClicked(false);
	};

	const handleFindLocation = () => {
		setFindLocationClicked(true);
		navigator.geolocation.getCurrentPosition(locationSuccess, locationError, {
			maximumAge: 60000,
			timeout: 5000,
			enableHighAccuracy: true,
		});
	};

	const handleSubmitButton = () => {
		if (viewport.longitude === 0 && viewport.latitude === 0) {
			if (locationInput?.current) {
				locationInput?.current?.focus();
			}
			return;
		}
		const dateFormatted = moment(date).format().split("T")[0];
		navigate(
			`/events/?longitude=${viewport.longitude}&latitude=${
				viewport.latitude
			}&zoom=${currentSearchZoom}&page=1${
				//@ts-ignore
				date?._isValid ? `&date=${dateFormatted}` : ""
			}`
		);
	};

	return (
		<div className="eventFinder">
			{/* <NavbarDatePicker handleOnChange={handleOnChange} date={date} /> */}
			<NavbarMapboxGeocoder
				geoCodeSearch={geoCodeSearch}
				setGeoCodeSearch={setGeoCodeSearch}
				clearSuggestions={clearSuggestions}
				geoCodeStatus={geoCodeStatus}
				geoCodeData={geoCodeData}
				usedFindLocation={usedFindLocation}
				setUsedFindLocation={setUsedFindLocation}
				locationInput={locationInput}
				findLocationClicked={findLocationClicked}
				handleFindLocation={handleFindLocation}
				onSelected={onSelected}
				selected={selected}
				handleSubmitButton={handleSubmitButton}
				geoCoderInputRef={geoCoderInputRef}
				setSearchIsActive={setSearchIsActive}
			/>
			<button
				className="bg-neutral-900 border-none py-4 px-6 md:py-5 md:px-7 rounded-20 w-full mt-5 hover:bg-neutral-800 hover:cursor-pointer"
				onClick={handleSubmitButton}
			>
				{/* <SearchIcon style={{ color: `#fff` }} /> */}
				<p className="bebas text-neutral-50 text-xl md:text-3xl font-semibold">
					Search
				</p>
			</button>
		</div>
	);
};

export default HomePageModal;
