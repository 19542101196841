import { ReactElement } from "react";
import { IRoute } from "../Types";

interface IPublicArguments {
	publicRoute: boolean;
	publicTo: string;
	route: IRoute;
}

interface IPrivateArguments {
	privateRoute: boolean;
	privateTo: string;
	route: IRoute;
}

interface IDefaultArguments {
	route: IRoute;
}

interface IRouteRender {
	public: (publicArgument: IPublicArguments) => ReactElement;
	private: (privateArgument: IPrivateArguments) => ReactElement;
	default: (defaultArgument: IDefaultArguments) => ReactElement;
}

//handle if person can access the route
const routerCheck = (
	route: IRoute,
	isLoggedIn: boolean
): { publicRoute: boolean; privateRoute: boolean } => {
	// If route is public - either use custom check, or check if the user isn't logged in
	const publicRoute =
		route.customCheck !== undefined && route.customTo !== undefined
			? route.customCheck
			: !isLoggedIn;

	// If route is private - check if the user is logged in
	const privateRoute =
		route.customCheck !== undefined ? route.customCheck : isLoggedIn;

	return { publicRoute, privateRoute };
};

// handle which route to go to
const routingTo = (route: IRoute): { publicTo: string; privateTo: string } => {
	//Public: If user has a custom check and custom 'to' redirect route, use that, else use home
	const publicTo =
		route.customCheck !== undefined && route.customTo ? route.customTo : "/";

	//Private: If user has a custom check and custom 'to' redirect route, use that, else go to login
	const privateTo =
		route.customCheck !== undefined && route.customTo ? route.customTo : "/";

	return { publicTo, privateTo };
};

export const getRoutes = (
	routeHandler: IRoute[],
	isLoggedIn: boolean,
	routerRender: IRouteRender
) => {
	return routeHandler.map((route) => {
		const routerCheckers: { publicRoute: boolean; privateRoute: boolean } =
			routerCheck(route, isLoggedIn);

		const routeTo: { publicTo: string; privateTo: string } = routingTo(route);

		if (route?.type === "public") {
			return routerRender.public({
				publicRoute: routerCheckers.publicRoute,
				publicTo: routeTo.publicTo,
				route,
			});
		}

		if (route?.type === "private") {
			return routerRender.private({
				privateRoute: routerCheckers.privateRoute,
				privateTo: routeTo.privateTo,
				route,
			});
		}

		return routerRender.default({ route });
	});
};
