//package imports
import { combineReducers } from "redux";
import { createSelectorHook } from "react-redux";

//reducers
import events, { initialState as eventsInitial } from "./eventsReducer";
import view, { initialState as viewInitial } from "./viewReducer";
import user, { initialState as userInitial } from "./userReducer";
import checkout, { initialState as checkoutInitial } from "./checkoutReducer";
import booking, { initialState as bookingInitial } from "./booking";
import { Console } from "console";

const appReducer = combineReducers({
	events,
	view,
	checkout,
	admin: user,
	booking,
});

export type RootState = ReturnType<typeof appReducer>;
export const useTypedSelector = createSelectorHook<RootState>();

export const rootReducer = (state: RootState, action: any) => {
	if (action.type === "CLEAR_STATE_AFTER_LOG_OUT") {
		console.log("CLEAR_STATE_AFTER_LOG_OUT: ", {
			events: {
				...eventsInitial,
				events: state.events.events,
				mapEvents: state.events.mapEvents,
			},
			view: {
				...viewInitial,
				showLoginModal: state.view.showLoginModal,
				viewMode: state.view.viewMode,
				showMap: state.view.showMap,
				showMapMobile: state.view.showMapMobile,
			},
			checkout: checkoutInitial,
			admin: userInitial,
			booking: bookingInitial,
		});
		return appReducer(
			{
				events: {
					...eventsInitial,
					events: state.events.events,
					mapEvents: state.events.mapEvents,
					pages: state.events.pages,
					eventLoading: state.events.eventLoading,
					eventsLoading: state.events.eventsLoading,
				},
				view: {
					...viewInitial,
					showLoginModal: state.view.showLoginModal,
					viewMode: state.view.viewMode,
					showMap: state.view.showMap,
					showMapMobile: state.view.showMapMobile,
				},
				checkout: checkoutInitial,
				admin: userInitial,
				booking: bookingInitial,
			},
			action
		);
	}

	return appReducer(state, action);
};
