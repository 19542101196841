import React from "react";

interface ILoginProps {
	setLogIn: any;
	email: any;
	setEmail: any;
	password: any;
	setPassword: any;
	onKeyDown: any;
	handleSubmit: any;
}

const Login = ({
	setLogIn,
	email,
	setEmail,
	password,
	setPassword,
	onKeyDown,
	handleSubmit,
}: ILoginProps) => {
	return (
		<>
			<div>
				<label
					htmlFor="email"
					className="block bebas mb-2 text-lg font-medium text-white"
				>
					Your email
				</label>
				<input
					type="email"
					name="email"
					id="email"
					className="border text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-neutral-800 border-neutral-500  text-white"
					placeholder="name@company.com"
					required
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					onKeyDown={(e) => onKeyDown(e)}
				/>
			</div>
			<div>
				<label
					htmlFor="password"
					className="block bebas mb-2 text-lg font-medium text-white"
				>
					Your password
				</label>
				<input
					type="password"
					name="password"
					id="password"
					placeholder="••••••••"
					className=" border text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-neutral-800 border-neutral-500 text-white"
					required
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					onKeyDown={(e) => onKeyDown(e)}
				/>
			</div>
			<button
				onClick={() => {
					console.log("submit");
					handleSubmit();
				}}
				className="w-full text-white bebas focus:ring-4 focus:outline-none  font-medium rounded-lg text-lg px-5 py-2.5 text-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800"
			>
				Login to your account
			</button>
			<div className="text-md bebas font-medium text-neutral-300">
				Not registered?{" "}
				<a
					href="#"
					className=" text-md bebas hover:underline text-blue-500"
					onClick={() => setLogIn(false)}
				>
					Create account
				</a>
			</div>
		</>
	);
};

export default Login;
