import axios from "axios";
import { Store } from "redux";

export const axiosInterceptorSetUp = (store: Store) => {
	const api = `/api/v1`;
	axios.interceptors.request.use((req) => {
		if (req.url && req.url[0] === "/") {
			const newUrl = api + req.url;
			req.url = newUrl;
			console.log(req.url);
		}

		return req;
	});

	axios.interceptors.response.use(
		(res) => {
			return res;
		},
		(error) => {
			const status = error.response.status;
			if (status === 401) {
				store.dispatch({ type: "CLEAR_STATE_AFTER_LOG_OUT" });
			}
			if (status === 403) {
				//TODO: read log
				console.log("logic to navigate away from page");
			}
			return Promise.reject(error);
		}
	);
};
