export const MAX_MOBILE_SCREEN_WIDTH = 639;
export const MAX_TABLET_SCREEN_WIDTH = 767;
export const MAX_LAPTOP_SCREEN_WIDTH = 1023;
// https://tailwindcss.com/docs/responsive-design#using-custom-breakpoints
// minus one from each breakpoint as it deals with min width and these values are max width
export const MAX_SCREEN_WIDTH = 1440;
//this value is the custom and global value (including px-[20px]) meaning max width is actually 1400px

export const NUMBER_OF_RESULTS_ON_RESULTS_PAGE = 24;
export const NUMBER_OF_RESULTS_ON_HOME_PAGE = 8;
