import React, { useEffect, useState, Suspense } from "react";
import { useTypedSelector } from "./redux/reducers/rootReducer";
import { usePrevious } from "./hooks";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Navbar from "./components/global/Navbar/Navbar";

// import { Login } from "./pages/Login/Login";
import { IRoute } from "./Types";

// import CompleteOrder from "./pages/CompleteOrder";
import LoginModal from "./pages/Login/EmailLogin/LoginModal";
// import FindPeopleAttending from "./pages/FindPeopleAttending/FindPeopleAttending";
import { getRoutes } from "./helpers";
import {
	MAX_LAPTOP_SCREEN_WIDTH,
	MAX_MOBILE_SCREEN_WIDTH,
	MAX_SCREEN_WIDTH,
	MAX_TABLET_SCREEN_WIDTH,
} from "./helpers/globalVariables";
import { setViewMode } from "./redux/actions/viewActions";
import { useDispatch } from "react-redux";

import { ErrorBoundary } from "./components/placeholders/ErrorBoundary";

import AgeRestrictionModal from "./components/global/AgeRestrictionModal/AgeRestrictionModal";
import useAnalytics from "./hooks/useAnalytics";

import { About } from "./pages/Information/About";

import {
	Home,
	SingleEvent,
	ResultPage,
	PrivacyPolicy,
	TermsAndConditions,
	ManageEventWrapper,
	Profile,
	Account,
	PageNotFound,
	HostApp,
} from "./lazyImport";
import { CircularProgress } from "@mui/material";

const Consumer: React.FC = () => {
	const isLoggedIn = useTypedSelector((state) => state.admin.loggedInUser.user);

	const sessionLoaded = useTypedSelector((state) => state.admin.sessionLoaded);
	const showLoginModal = useTypedSelector((state) => state.view.showLoginModal);

	const [loading, setLoading] = useState<boolean>(true);
	const prevSessionLoaded = usePrevious(sessionLoaded);

	const dispatch = useDispatch();

	useAnalytics();

	const updateViewMode = () => {
		if (window.innerWidth <= MAX_MOBILE_SCREEN_WIDTH) {
			dispatch(setViewMode("mobile"));
		} else if (window.innerWidth <= MAX_TABLET_SCREEN_WIDTH) {
			dispatch(setViewMode("tablet"));
		} else if (window.innerWidth <= MAX_LAPTOP_SCREEN_WIDTH) {
			dispatch(setViewMode("laptop"));
		} else if (window.innerWidth <= MAX_SCREEN_WIDTH) {
			dispatch(setViewMode("screen"));
		}
	};

	useEffect(() => {
		// check if sessionLoaded has changed and that its prevSessionLoaded wasn't undefined
		if (
			prevSessionLoaded !== sessionLoaded &&
			prevSessionLoaded !== undefined
		) {
			setLoading(false);
		}

		updateViewMode();
		window.onresize = () => {
			updateViewMode();
		};
	}, [prevSessionLoaded, sessionLoaded]);

	const routeHandler: IRoute[] = [
		{
			path: "/",
			component: Home,
		},
		// {
		// 	path: "/login",
		// 	component: Login,
		// 	// navBarTitle: "Login",
		// 	type: "public",
		// },
		{
			path: "/event/:uuid",
			component: SingleEvent,
		},
		// {
		// 	path: "/event/:uuid/mates",
		// 	component: FindPeopleAttending,
		// },
		// {
		// 	path: "/order/complete/:currentTransactionUuid",
		// 	component: CompleteOrder,
		// 	type: "private",
		// 	customCheck: isLoggedIn,
		// 	customTo: "/login",
		// },
		{
			path: "/events",
			component: ResultPage,
		},
		{
			path: "/profile",
			component: Profile,
			type: "private",
		},
		{
			path: "/account",
			component: Account,
			type: "private",
		},
		{
			path: "/host",
			component: HostApp,
			type: "private",
		},
		{
			path: "/host/event/:eventUuid",
			component: ManageEventWrapper,
			type: "private",
		},
		{
			path: "/privacy-policy",
			component: PrivacyPolicy,
		},
		{
			path: "/terms-and-conditions",
			component: TermsAndConditions,
		},
		{
			path: "/about-us",
			component: About,
		},
	];

	const routes = getRoutes(routeHandler, isLoggedIn, {
		public: ({ publicRoute, publicTo, route }) => (
			<Route
				key={route.path}
				path={route.path}
				element={
					publicRoute ? <route.component /> : <Navigate to={publicTo} replace />
				}
			/>
		),
		private: ({ privateRoute, privateTo, route }) => (
			<Route
				key={route.path}
				path={route.path}
				element={
					privateRoute ? (
						<route.component />
					) : (
						<Navigate to={privateTo} replace />
					)
				}
			/>
		),
		default: ({ route }) => (
			<Route key={route.path} path={route.path} element={<route.component />} />
		),
	});
	return (
		<>
			<Navbar routeHandler={routeHandler} loading={loading} />
			<ErrorBoundary fallBack={<PageNotFound fallBack />}>
				<>
					{!loading && (
						<Suspense
							fallback={
								<div className="w-screen h-screen flex justify-center items-center">
									<CircularProgress className="w-[100px] h-[100px]" />
								</div>
							}
						>
							<Routes>
								{routes}
								<Route path="*" element={<PageNotFound />} />
							</Routes>
						</Suspense>
					)}
					<LoginModal show={showLoginModal} />
				</>
			</ErrorBoundary>
			{isLoggedIn && !isLoggedIn.age && <AgeRestrictionModal />}
		</>
	);
};

const BrowserRouterWrapper = () => {
	return (
		<BrowserRouter>
			<Consumer />
		</BrowserRouter>
	);
};

export default BrowserRouterWrapper;
