import React, { ErrorInfo } from "react";

interface IErrorBoundaryProps {
	fallBack: JSX.Element;
	children: JSX.Element;
}

export class ErrorBoundary extends React.Component<
	IErrorBoundaryProps,
	{ hasError: boolean }
> {
	constructor(props: IErrorBoundaryProps) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error: any) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	// componentDidCatch(error: any, errorInfo: ErrorInfo) {
	// 	// You can also log the error to an error reporting service
	// 	logErrorToMyService(error, errorInfo);
	// }

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return this.props.fallBack;
		}

		return this.props.children;
	}
}
