import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { isSignedIn, hasBasket } from "./redux/actions/userActions";
import "./styles/global.scss";
import Consumer from "./Consumer";
import "./index.css";

const ConsumerApp: React.FC = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(isSignedIn());
		dispatch(hasBasket());
	}, [dispatch]);

	return <Consumer />;
};

export default ConsumerApp;
