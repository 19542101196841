import React, { FC } from "react";
import { Provider } from "react-redux";
import ConsumerApp from "./ConsumerApp";
import configureStore from "./redux/store";
import { axiosInterceptorSetUp } from "./redux/helpers/axios";

const store = configureStore();
axiosInterceptorSetUp(store);
const ConsumerAppEntry = () => (
	<>
		<Provider store={store}>
			<ConsumerApp />
		</Provider>
	</>
);
export type AppDispatch = typeof store.dispatch;
export default ConsumerAppEntry;
