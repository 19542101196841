import React, { useState, useEffect } from "react";
import "../../../styles/components/loginModal.scss";
import { showLoginModal } from "../../../redux/actions/viewActions";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import SocialMediaLogins from "../SocialMediaLogins";
import { SpotifyLoginHook } from "../../../hooks/spotifyLoginHook";
import EmailLogin from "../EmailLogin";
import GlobalModal from "../../../components/global/GlobalModal";

const LoginModal: React.FC<{ show: boolean }> = ({ show }) => {
	const [logIn, setLogIn] = useState(true);
	const [spotifyMiddleStage, setSpotifyMiddleStage] = useState(false);

	const dispatch = useDispatch();

	if (!show) return null;
	return (
		<GlobalModal
			onBackgroundClick={() => dispatch(showLoginModal(false))}
			className="loginModalAlone max-w-[300px] sm:max-w-[400px]"
		>
			<div className="p-[5px] sm:p-[10px] md:p-[30px] w-full ">
				{spotifyMiddleStage ? (
					<CircularProgress />
				) : (
					<>
						<EmailLogin
							logIn={logIn}
							setLogIn={setLogIn}
							spotifyMiddleStage={spotifyMiddleStage}
							setSpotifyMiddleStage={setSpotifyMiddleStage}
						/>
						{/* <div className="orDivider">
							<span />
							Or
							<span />
						</div>
						<SocialMediaLogins
							spotifyMiddleStage={spotifyMiddleStage}
							setSpotifyMiddleStage={setSpotifyMiddleStage}
							logIn={logIn}
						/> */}
					</>
				)}
			</div>
		</GlobalModal>
	);
};

export default LoginModal;
