import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function useAnalytics() {
	const location = useLocation();

	useEffect(() => {
		const page_path = location.pathname + location.search;
		//@ts-ignore
		if (typeof window.gtag !== "undefined") {
			//@ts-ignore
			window.gtag("event", "page_view", { page_path });
		}
	}, [location]);
}

export default useAnalytics;
