import React from "react";
import FormInput from "../../../components/shared/global/Inputs/FormInput";

interface ISignUpProps {
	setLogIn: any;
	email: any;
	setEmail: any;
	fullName: any;
	setFullName: any;
	passwordSignUp: any;
	setPasswordSignUp: any;
	confirmPassword: any;
	setConfirmPassword: any;
	onKeyDown: any;
	handleSubmit: any;
	age: any;
	setAge: any;
}

const SignUp = ({
	setLogIn,
	email,
	setEmail,
	fullName,
	setFullName,
	passwordSignUp,
	setPasswordSignUp,
	confirmPassword,
	setConfirmPassword,
	onKeyDown,
	handleSubmit,
	age,
	setAge,
}: ISignUpProps) => {
	return (
		<>
			<div className="grid grid-cols-3 gap-x-3">
				<div className="col-span-2">
					<FormInput
						label={"Full Name"}
						value={fullName}
						onChange={(e) => setFullName(e.target.value)}
						onKeyDown={(e) => onKeyDown(e)}
						required
					/>
				</div>

				<FormInput
					type="number"
					value={age}
					required
					onChange={(e) => setAge(parseInt(e?.target.value))}
					label="Age"
					min={18}
				/>
			</div>
			<FormInput
				type="email"
				label={"Your email"}
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				onKeyDown={(e) => onKeyDown(e)}
				required
			/>
			<FormInput
				type="password"
				label={"Your password"}
				value={passwordSignUp}
				onChange={(e) => setPasswordSignUp(e.target.value)}
				onKeyDown={(e) => onKeyDown(e)}
				required
			/>
			<FormInput
				type="password"
				label={"Confim password"}
				value={confirmPassword}
				onChange={(e) => setConfirmPassword(e.target.value)}
				onKeyDown={(e) => onKeyDown(e)}
				required
			/>

			<button
				onClick={() => {
					console.log("submit");
					handleSubmit();
				}}
				className="w-full text-white bebas focus:ring-4 focus:outline-none font-medium rounded-lg text-lg px-5 py-2.5 text-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800"
			>
				Create your account
			</button>
			<div className="text-md bebas font-medium text-neutral-300">
				Already have an account?{" "}
				<a
					href="#"
					className=" text-md bebas hover:underline text-blue-500"
					onClick={() => setLogIn(true)}
				>
					Log in to your account
				</a>
			</div>
		</>
	);
};

export default SignUp;
