import axios from "axios";
import { googleLogoutHandler } from "../../helpers";
import { IEvent, IUser } from "../../globalTypes";
import { AppDispatch } from "../../ConsumerAppEntry";

interface IUserResponse {
	data: {
		auth: boolean;
		message: string;
		user: IUser;
		token: string;
	};
}
const handleUser = (response: IUserResponse, dispatch: AppDispatch) => {
	const { user, auth } = response?.data;
	return dispatch({ type: "LOG_IN", payload: { user, auth } });
};

const getNumberAndbirthday = (
	googleData: any
):
	| undefined
	| Promise<{
			phoneNumber: string;
			birthday: {
				day: number;
				month: number;
				year?: number;
			};
	  }> => {
	return new Promise((res, error) => {
		axios
			.get(
				`https://people.googleapis.com/v1/people/${googleData.googleId}?personFields=phoneNumbers,birthdays`,
				{
					headers: {
						Authorization: "Bearer " + googleData.accessToken,
					},
				}
			)
			.then((numberAndBirthday) => {
				const phoneNumber =
					//@ts-ignore
					numberAndBirthday?.data?.phoneNumbers.find(
						(number: any) => number.metadata.primary
						//@ts-ignore
					) || numberAndBirthday?.data?.phoneNumbers[0];

				const birthday =
					//@ts-ignore
					numberAndBirthday?.data?.birthdays[1]?.date ||
					//@ts-ignore
					numberAndBirthday?.data?.birthdays[0]?.date;

				res({
					phoneNumber: phoneNumber.value,
					birthday: birthday,
				});
			})
			.catch((err) => {
				error(err);
			});
	});
};

export const facebookSignIn =
	(facebookData: any) => (dispatch: AppDispatch) => {
		axios
			.post("/auth/facebook/signIn", facebookData)
			.then((response) => handleUser(response, dispatch))
			.catch((err) => {
				console.log(err);
			});
	};

export const facebookSignUp =
	(facebookData: any) => (dispatch: AppDispatch) => {
		axios
			.post("/auth/facebook/signUp", facebookData)
			.then((response) => handleUser(response, dispatch))
			.catch((err) => {
				console.log(err);
			});
	};

export const signInEmail =
	({ email, password }: any) =>
	async (dispatch: AppDispatch) => {
		return (
			axios
				.post("/auth/email/signIn", { email, password })
				//@ts-ignore
				.then((response) => handleUser(response, dispatch))
				.catch((err) => {
					return { errorMessage: err.response.data.message };
				})
		);
	};

export const signUpEmail =
	({ fullName, email, password, confirmPassword, age }: any) =>
	async (dispatch: AppDispatch) => {
		return (
			axios
				.post("/auth/email/signUp", {
					fullName,
					email,
					password,
					confirmPassword,
					age,
				})
				//@ts-ignore
				.then((response) => handleUser(response, dispatch))
				.catch((err) => {
					console.log(err.response.data.message);
					return { errorMessage: err.response.data.message };
				})
		);
	};

export const logOut = () => (dispatch: AppDispatch) => {
	axios
		.post("/auth/signOut")
		.then((res) => {
			console.log(res);
			dispatch({ type: "CLEAR_STATE_AFTER_LOG_OUT" });
		})
		.catch((e) => {
			return false;
		})
		.finally(() => {});
	googleLogoutHandler();
};

export const requestInviteToEvent =
	(event: IEvent) => async (dispatch: AppDispatch) => {
		return axios
			.put(`/profile/requestInvite/${event._id}`)
			.then((res) => {
				//@ts-ignore
				const user = res.data.user;
				console.log("data: ", user);
				dispatch({
					type: "UPDATE_USER",
					payload: user,
				});

				dispatch({
					type: "UPDATE_REQUESTED_EVENTS_ON_REQUEST",
					payload: event,
				});

				// dispatch({
				// 	type: "UPDATE_REQUESTED_EVENTS",
				// 	payload: data,
				// });

				return user;
			})
			.catch((err) => false);
	};

export const addSavedEvent =
	(eventUuid: string, userUuid: string, calledOnEventsPage: boolean = false) =>
	async (dispatch: AppDispatch) => {
		axios
			.put(`/profile/${userUuid}/event/${eventUuid}`)
			.then((response) => {
				//@ts-ignore
				dispatch({ type: "UPDATE_USER", payload: response.data?.user });

				dispatch({
					type: "UPDATE_SAVED_EVENTS_ON_SAVE",
					//@ts-ignore
					payload: response.data?.event,
				});
				// if (calledOnEventsPage) {
				// 	dispatch({
				// 		type: "UPDATE_EVENT_WITH_USER_WHO_SAVED",
				// 		//@ts-ignore
				// 		payload: response.data?.user,
				// 		eventUuid,
				// 	});
				// }
			})
			.catch((error) => console.log(error));
	};

export const removeSavedEvent =
	(eventUuid: string, userUuid: string) => async (dispatch: AppDispatch) => {
		axios
			.delete(`/profile/${userUuid}/event/${eventUuid}`)
			.then((response) => {
				//@ts-ignore
				dispatch({ type: "UPDATE_USER", payload: response.data?.user });
				dispatch({ type: "REMOVE_SAVED_EVENTS", payload: eventUuid });
			})
			.catch((error) => console.log(error));
	};

export const updateUserAge = (age: number) => async (dispatch: AppDispatch) => {
	axios
		.put(`/profile/ageConfirmation`, {
			age,
		})
		.then((response) => {
			//@ts-ignore
			dispatch({ type: "UPDATE_USER", payload: response.data?.user });
		})
		.catch((error) => console.log(error));
};

export const userHasPassword = async () => {
	return await axios
		.get(`/profile/userHasPassword`)
		.then((response) => {
			//@ts-ignore
			return response.data.hasPassword;
		})
		.catch((error) => console.log(error));
};

export const changePassword = async ({
	password,
	newPassword,
	confirmNewPassword,
}: {
	password: string;
	newPassword: string;
	confirmNewPassword: string;
}) => {
	return await axios
		.put(`/profile/changePassword`, {
			password,
			newPassword,
			confirmNewPassword,
		})
		.then((response) => {
			//@ts-ignore
			return response.data?.passwordUpdated;
		})
		.catch((error) => console.log(error));
};

export const signUpWithSpotify = () => async (dispatch: AppDispatch) => {
	axios
		.get(`/auth/spotify/signUp/`)
		.then((res) => {
			console.log(res);
		})
		.catch((err) => console.log(err));
};

export const getSpotifyArtist = (artist: string) => {
	axios
		.get("https://api.spotify.com/v1/search", {
			data: {
				q: artist,
				type: "album",
			},
		})
		.then((res) => {
			console.log(res);
		})
		.catch((err) => {
			console.log(err);
		});
};

export const isSignedIn = () => (dispatch: AppDispatch) => {
	axios
		.get("/auth/isSignedIn")
		.then((res) => {
			//@ts-ignore
			const user = res.data.user;
			dispatch({
				type: "GET_SESSION",
				payload: { user, auth: true },
			});
			dispatch({ type: "SESSION_L0ADED" });
		})
		.catch((err) => {
			dispatch({
				type: "GET_SESSION",
				payload: { user: undefined, auth: false },
			});
			dispatch({ type: "SESSION_L0ADED" });
		});
};

export const hasBasket = () => (dispatch: AppDispatch) => {
	const currentBasket = localStorage.getItem("basket");
	if (currentBasket) {
		const currentBasketJson = JSON.parse(currentBasket);
		dispatch({ type: "ADD_TO_BASKET", payload: currentBasketJson });
	}
};

export const getUserCards = (customerId: string) => (dispatch: AppDispatch) => {
	axios
		.get(`/payment/cards/${customerId}`)
		.then((res) => console.log(res))
		.catch((e) => console.log(e));
};

export const updateProfileCard =
	(data: { name: string; selfDescription: string } | FormData) =>
	(dispatch: AppDispatch) => {
		return axios
			.put(`/profile/updateProfileCard`, data)
			.then((res) => {
				//@ts-ignore
				const data = res.data?.user;
				dispatch({ type: "UPDATE_USER", payload: data });
				return data;
			})
			.catch((e) => {
				return e;
			});
	};
