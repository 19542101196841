import React, { ReactElement, useEffect } from "react";
import ReactDOM from "react-dom";

interface IGlobalModalProps {
	children?: ReactElement;
	onBackgroundClick?: () => void;
	className: string; //NEEDED. MUST SET A MAX WIDTH TO THE DIV WITH THE CLASSNAME PROP VIA THIS PROP
}

const htmlElement = document.querySelector(":root");
const GlobalModal = ({
	children,
	onBackgroundClick,
	className,
}: IGlobalModalProps) => {
	//remove scroll when modal is open
	useEffect(() => {
		htmlElement?.classList.add("modalShowing");
		document.body.classList.add("modalShowing");

		const clickListener = (e: MouseEvent) => {
			//@ts-ignore
			const currentTarget = e.target.classList;
			const isBackgroundClick = currentTarget.contains("globalModalBackground");
			if (isBackgroundClick) {
				onBackgroundClick && onBackgroundClick();
			}
		};

		window.addEventListener("click", clickListener);
		return () => {
			document.body.classList.remove("modalShowing");
			htmlElement?.classList.remove("modalShowing");
			window.addEventListener("click", clickListener);
		};
	}, []);

	return ReactDOM.createPortal(
		<div className="globalModalBackground fixed top-0 left-0 w-full h-full z-40 flex justify-center items-center bg-neutral-900/[0.7] transition-all">
			<div className="globalModalBackground max-h-full overflow-y-scroll noScrollbar w-full fadeInBottomModal">
				<div className={`bg-neutral-800 rounded-20 mx-auto my-10 ${className}`}>
					{children}
				</div>
			</div>
		</div>,
		//@ts-ignore
		document.getElementById("app-modals")
	);
};

export default GlobalModal;
