import React, { useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import "./NavbarSearch.scss";
import HomePageModal from "../../Home/HomepageModal/HomePageModal";

const NavbarSearch = ({ searchIsActive, setSearchIsActive }: any) => {
	const [selected, setSelected] = useState<{ formatted_address?: string }>({});
	const geoCoderInputRef = useRef<HTMLInputElement>(null);
	useEffect(() => {
		const handleEsc = (e: KeyboardEvent) => {
			console.log("log: ", e.keyCode);
			const ESC = 27;
			if (e.keyCode === ESC) {
				setSearchIsActive(false);
			}
		};
		if (searchIsActive) {
			// handle modal
			document.body.classList.add("navModalShowing");

			//handle esc key to close
			document.addEventListener("keydown", handleEsc);
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
			// geoCoderInputRef.current?.focus();
		}
		return () => {
			document.body.classList.remove("navModalShowing");
			document.removeEventListener("keydown", handleEsc);
		};
	}, [searchIsActive]);

	return (
		<div
			className="navbar__search border border-solid border-neutral-900"
			onClick={() => setSearchIsActive(true)}
		>
			<div className="navbar__search__inner ">
				<div className="navbar__search__inner__text whitespace-pre text-ellipsis overflow-hidden max-w-maincard">
					{selected?.formatted_address || "Search"}
				</div>
				<div className="navbar__search__inner__button">
					<SearchIcon />
				</div>
			</div>

			<HomePageModal
				geoCoderInputRef={geoCoderInputRef}
				selected={selected}
				setSelected={setSelected}
				setSearchIsActive={setSearchIsActive}
			/>
		</div>
	);
};

export default NavbarSearch;
